<template>
  <div class="wrp">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item>Our Brands</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bd">
      <img src="../assets/imgs/uploads/OEM ODM PRODUCTS.jpg" alt="" style="width: 100%;">
    </div>
    <div class="brands">
      <p class="title">Our Brands</p>
      <p class="center">Over the years, we have been dedicated to a single goal: creating entirely new life experiences
        for pet and their people. Our brand</p>
      <p class="center">emphasizes innovative, safe, and enjoyable designs, and we continuously develop our products with
        our perspective, mission, and</p>
      <p class="center">values, driving forward the industry and benefiting society.</p>
    </div>
    <div class="tip1">
      <div style="flex: 1;">
        <img src="../assets/imgs/uploads/Group 324.png" alt="" style="width: 303px;height: 67px;">

        <p style="margin-top: 14px;">GiGwi, founded in 2010 in Hong Kong, has always embraced a simple and </p>
        <p>sincere mission: to enrich your daily life with your cherished pets. We are </p>
        <p>dedicated to fostering joy between pets and their human companions,</p>
        <p>ensuring that both can share happy moments together. Our global team of</p>
        <p>designers is committed to crafting pet toys that transcend boundaries, </p>
        <p>offering safe, high-quality products that cater to your pet's needs. Rooted</p>
        <p>in love and companionship, we strive to be your loyal partners in pet care.</p>
        <p>Let us celebrate the bond between you and your beloved pets.</p>
        <router-link to="/exploregigwi"><button class="btn"
            style="background-color: #CA141D;">Explore</button></router-link>
      </div>
      <div style="flex: 1;">
        <img src="../assets/imgs/uploads/gigwi.jpg" alt="" style="width: 637px;height: 425px;">
      </div>
    </div>
    <div class="tip2">
      <div style="flex: 1;">
        <img src="../assets/imgs/uploads/Rectangle 217.png" alt="" style="width: 637px;height: 425px;">
      </div>
      <div style="flex: 1;margin-left: 62px;">
        <img src="../assets/imgs/uploads/Rectangle 13.png" alt="" style="width: 303px;height: 67px;">
        <p style="margin-top: 14px;">Gifts for Paws is dedicated to creating a unique and joyful world for you</p>
        <p>and your beloved pets. Each toy is carefully designed to not only meet the</p>
        <p>play needs of pets but also to showcase their distinctive personalities</p>
        <p>while playing. Pets are our family and friends, so we not only focus on </p>
        <p>appearance but also prioritize safety and comfort. We use high-quality </p>
        <p>materials to ensure durability and safety. Gifts for Paws helps you establish</p>
        <p>a deeper emotional connection with your pets. Whether it's playing, </p>
        <p>interacting, or simply being together, we aim to enhance the bond between</p>
        <p>you and your beloved pets, allowing you both to enjoy each other's </p>
        <p>company to the fullest.</p>
        <router-link to="/exploregp"><button class="btn" style="background-color: #CE9F6A;">Explore</button></router-link>
      </div>
    </div>
    <div class="tip1">
      <div style="flex: 1;">
        <img src="../assets/imgs/uploads/Rectangle 131.png" alt="" style="width: 303px;height: 67px;">
        <p style="margin-top: 14px;">PetGeek specializing in pet toys and supplies, with a vision to raise</p>
        <p>awareness of pets' needs and welfare, and to create a happier and better</p>
        <p>environment for them. We offer high-quality products at affordable prices,</p>
        <p>ensuring that every pet enthusiast can easily access the necessary pet</p>
        <p>supplies. Our mission is to bring joy to more pets through toys, while also</p>
        <p>providing a more convenient lifestyle for both pets and their owners.</p>
        <p>Caring for pets and sharing happiness is our motto.</p>
        <router-link to="/explorepetgeek"><button class="btn"
            style="background-color: #97CAEB;">Explore</button></router-link>
      </div>
      <div style="flex: 1;">
        <img src="../assets/imgs/uploads/petgeek.jpg" alt="" style="width: 637px;height: 425px;">
      </div>
    </div>
    <div class="tip2">
      <div style="flex: 1;">
        <img src="../assets/imgs/uploads/skipdawg.jpg" alt="" style="width: 637px;height: 425px;">
      </div>
      <div style="flex: 1;margin-left: 62px;">
        <img src="../assets/imgs/uploads/Rectangle 132.png" alt="" style="width: 303px;height: 67px;">
        <p style="margin-top: 14px;">SKIPDAWG is dedicated to providing high-quality outdoor gear for your</p>
        <p>beloved canine companions, promoting joy and health for both owners and</p>
        <p>dogs in the great outdoors. We strive for every dog to freely roam and</p>
        <p>explore, unleashing their vitality to the fullest. Carefully selected materials</p>
        <p>ensure comfort and durability in SKIPDAWG products. We prioritize the</p>
        <p>physical and mental well-being of dogs, fostering closer bonds between</p>
        <p>owners and their furry friends. Our aim is to keep dogs vibrant and happy</p>
        <p>during outdoor activities. Embrace nature, where life continues and the</p>
        <p>spirit of movement never ends.</p>
        <router-link to="/exploreskipdawg"><button class="btn"
            style="background-color: #ED6A00;">Explore</button></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Explore our extensive collection of cat and dog toys from top brands. Find the perfect toys to keep your pets entertained and happy. Discover leading brands of cat and dog toys at our online pet toy store. Browse a wide selection of high-quality toys for your furry friends.Shop for premium cat and dog toys from renowned brands on our website. Treat your pets to the best toys for endless hours of fun.Elevate your pets playtime with toys from trusted brands available on our online pet toy marketplace. Choose from a diverse range of toys designed for both cats and dogs.Browse through our curated selection of cat and dog toys from reputable brands. Spoil your pets with toys crafted for durability, safety, and enjoyment.'
      },
      {
        name: 'keywords',
        content: 'dog brands,best dog brands,top dog brands,best dog brands food,good dog brands,luxury dog brands,cat brands,best cat brands,luxury cat brands,wet cat brands,cat brands food,Pet toy brands,Cat and dog toys,Premium pet toys,Top pet toy brands,Pet toy marketplace,High-quality pet toys'
      }
    ]
  }
}
</script>

<style scoped>
.wrp {
  width: 1280px;
  margin: 0 auto 64px;
}

.bread {
  margin-top: 30px;
}

.bd {
  margin-top: 30px;
}

::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #000;
}

.brands {
  margin-top: 60px;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 15px;
}

.center {
  display: flex;
  justify-content: center;
}

.tip1 {
  margin-top: 60px;
  padding: 30px;
  height: 488px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #ccc;
  display: flex;
  align-items: center;
}

.tip2 {
  margin-top: 60px;
  padding: 25px;
  height: 488px;
  display: flex;
  align-items: center;
}

.btn {
  margin-top: 30px;
  width: 236px;
  height: 53px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  border: none;
  cursor: pointer;
}
</style>